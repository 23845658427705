import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataBank: null,
    respDataPaymentBank: null,
  },
  getters: {
  },
  mutations: {
    GET_CONFIG_APPLICATION(state, payload) {
      state.respData = payload
    },
    GET_BANK_LIST(state, payload) {
      state.respDataBank = payload
    },
    GET_PAYMENT_BANK_LIST(state, payload) {
      state.respDataPaymentBank = payload
    },
    ADD_PAYMENT_BANK_LIST(state, payload) {
      if (state.respDataPaymentBank != null) {
        state.respDataPaymentBank.data.unshift(payload)
        // eslint-disable-next-line operator-assignment
        state.respDataPaymentBank.max = state.respDataPaymentBank.max + 1
      }
    },
    SWITCH_CHANGE(state, item) {
      const nameChild = item.name.split('.')

      if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
      } else if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.status
      } else {
        state.respData[item.name] = item.status
      }
    },
    INPUT_CHANGE(state, item) {
      const nameChild = item.name.split('.')

      if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.value
      } else if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.value
      } else {
        state.respData[item.name] = item.value
      }
    },
    UPDATE(state, payload) {
      state.respData = payload

    },
    UPDATE_PAYMENT_BANK_LIST(state, payload) {
      const productIndex = state.respDataPaymentBank.data.findIndex(p => p._id === payload._id)
      if (productIndex > -1) {
        Object.assign(state.respDataPaymentBank.data[productIndex], payload)
      } else {
        state.respDataPaymentBank.data.unshift(payload)
      }
    },
    REMOVE_PAYMENT_BANK_LIST(state, id) {
      if (state.respDataPaymentBank != null) {
        const ItemIndex = state.respDataPaymentBank.data.findIndex(p => p._id === id)
        state.respDataPaymentBank.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respDataPaymentBank.max = state.respDataPaymentBank.max - 1
      }
    },
  },
  actions: {
    get({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/application/eCommerce/applicationSettings/get')
          .then(response => {

            commit('GET_CONFIG_APPLICATION', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    inputChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/applicationSettings/inputChange', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    multiSelectChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/applicationSettings/multiSelectChange', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/applicationSettings/switchChange', item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/applicationSettings/getBankList/${item.paymentMethod}`)
          .then(response => {
            commit('GET_BANK_LIST', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getPaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/applicationSettings/getPaymentBankList/${item.paymentMethod}?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_PAYMENT_BANK_LIST', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addPaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/web/application/eCommerce/applicationSettings/addPaymentBankList/${item.paymentMethod}`, item)
          .then(response => {
            commit('ADD_PAYMENT_BANK_LIST', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updatePaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/applicationSettings/updatePaymentBankList/${item.paymentMethod}`, item)
          .then(response => {
            commit('UPDATE_PAYMENT_BANK_LIST', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    deletePaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/applicationSettings/deletePaymentBankList/${item.paymentMethod}`, item)
          .then(response => {
            commit('REMOVE_PAYMENT_BANK_LIST', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    switchChangePaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/application/eCommerce/applicationSettings/switchChangePaymentBankList/${item.paymentMethod}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            commit('UPDATE_PAYMENT_BANK_LIST', response.data.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    activatePaymentBankList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/application/eCommerce/applicationSettings/activatePaymentBankList/${item.paymentMethod}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            // commit('UPDATE_PAYMENT_BANK_LIST', response.data.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    activatePaypal({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/application/eCommerce/paypal/activate`, item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    confirmPaypal({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/application/eCommerce/paypal/confirm`, item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
